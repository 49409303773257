import styled from '@emotion/styled';
import { useCallback } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { MdOpenInNew } from 'react-icons/md';
import { RiEditLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { Card as PageCard, Pill, RoundButtonBase, ToolTip } from '../../common';
import { useApiService } from '../../context';
import { BADGE_STYLE } from '../../common/constants';
import { SimpleDialogContainer, simpleConfirm } from 'react-simple-dialogs';

const RoundedPill = styled.div`
  padding: 7px;
  color: rgb(255, 255, 255);
  background-color: rgba(89, 161, 49, 0.66);
  font-size: 12px;
`;

const RoundedCircle = styled.div`
  padding: 4px;
  background: #0000004f;
  line-height: 7px;
  cursor: pointer;
  &:hover {
    background: #00000098;
  }
`;

const TimeCheckStyledDiv = styled.span`
  background: #00000036;
  border-radius: 5px;
  padding: 2px;
  margin-left: 8px;
  font-size: 11px;
  letter-spacing: 0.04rem;
  &:hover {
    background: #000000a1;
  }
`;

const StyledCard = styled.div`
  cursor: pointer;
  // width: 15rem;
  font-size: 12px;
  padding-top: 0px;
  min-height: 211px;
  border: #af97d8 solid 3px;
  img {
    width: 50%;
  }

  div > svg {
    font-size: 34px;
    color: #af97d8;
  }
  &:hover {
    border: #b2d897 solid 3px;

    div > svg {
      color: #b2d897;
    }
  }
`;
const StyledDescriptionDiv = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;
const Card = ({
  name,
  handle,
  description,
  uid,
  setNewGroup,
  setVisibleLeft,
  canRotaPadUserUpdate = false,
}) => {
  const {
    deleteDoc,
    setRotaPadInvitations,
    fetchData,
    database,
    setRotaPadAbsences,
    setRotaPadWorkOrders,
    setRotaPadWorkSpaces,
    setRotaPadGroups,
  } = useApiService();
  const navigate = useNavigate();

  const handleClick = useCallback(async () => {
    if (
      await simpleConfirm({
        title: '🗑️ Delete Group',
        message: `Do you want to delete "${name}"?`,
        confirmLabel: 'Delete',
        cancelLabel: 'Cancel',
      })
    ) {
      console.log('Confirmed! ;)');
      const DeleteGroup = await deleteDoc({
        db: database,
        path: 'groups/' + uid,
      });
    } else {
      console.log('Not confirmed. 🥲');
    }

    // const fetchGroups = await fetchData({
    // 	db: database,
    // 	path: "groups",
    // });

    // // console.log("absences ===== ", fetchGroups);
    // if (fetchGroups) {
    // 	setRotaPadGroups(fetchGroups);
    // }
  }, [
    database,
    deleteDoc,
    fetchData,
    setRotaPadGroups,
    uid,
    simpleConfirm,
    name,
  ]);
  return (
    <>
      <div className='col-xs-12 col-sm-6 col-md-4 col-lg-3'>
        <PageCard className='card shadow-sm mb-3 '>
          <div className='ps-3 bg-transparent pt-3'>
            <Pill
              content={handle}
              bgStyle={{ ...BADGE_STYLE.Admin }}
              toolTipMessage='Group handle'
            />
          </div>

          {description && description !== '' && (
            <div className='card-body '>
              <StyledDescriptionDiv className='pt-0'>
                {description}
              </StyledDescriptionDiv>
            </div>
          )}

          {canRotaPadUserUpdate && (
            <div className='card-footer bg-transparent '>
              <ToolTip
                placement='top'
                overlay={<span style={{ fontSize: 10 }}>Update</span>}
              >
                <RoundButtonBase
                  variant='outline'
                  onClick={() => {
                    setNewGroup({
                      name,
                      handle,
                      description,
                      uid,
                    });
                    setVisibleLeft(true);
                  }}
                  type='button'
                  size='small'
                  className={`me-2 `}
                >
                  <RiEditLine />
                </RoundButtonBase>
              </ToolTip>
              <ToolTip
                placement='top'
                overlay={<span style={{ fontSize: 10 }}>Delete</span>}
              >
                <RoundButtonBase
                  variant='outline'
                  onClick={handleClick}
                  type='button'
                  size='small'
                  className={` me-2 `}
                >
                  <AiOutlineDelete />
                </RoundButtonBase>
              </ToolTip>
              <SimpleDialogContainer
                primaryColor='#dc3545'
                primaryHoverColor='#a42531'
              />
            </div>
          )}
        </PageCard>
      </div>
    </>
  );
};

export default Card;
