// Login component

import styled from '@emotion/styled';
import {
  setPersistence,
  signInWithEmailAndPassword,
  browserSessionPersistence,
} from 'firebase/auth';
import { ref, set } from 'firebase/database';
import { useEffect, useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import { ButtonBase, ToolTip } from '../common';
import { TOAST_TYPE } from '../common/constants';
import { useBranding } from '../common/hooks/useBranding';
import { useToast } from '../common/hooks/useToast';
import Logo from '../common/img/logo.png';
import { FiInfo } from 'react-icons/fi';
import { Helmet } from 'react-helmet';
// import './index.scss';

const StyledDiv = styled.div`
  cursor: pointer;
  z-index: 200;
  position: absolute;
  right: 15px;
  font-size: large;
  color: #6c757d;
  top: 16px;

  &:hover {
    color: #7f00ff;
  }
`;

const ErrorStyledDiv = styled.div`
  background-color: #fff;
  padding: 24px;
`;

const StyledInput = styled.input`
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

function writeUserData(db, userId, name, email, imageUrl) {
  // const db = getDatabase();
  // set(ref(db, "users/" + userId), {
  // 	username: name,
  // 	email: email,
  // 	profile_picture: imageUrl,
  // });

  set(ref(db, 'rotas/2023/workorders/555'), {
    title: '555',
  });
}
const ErrorPage = ({ app, analytics, auth, database }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [showPassword, setShowPassword] = useState({
    hide: true,
    info: 'Show password',
  });

  const navigate = useNavigate();
  let { pathname } = useLocation();
  const { triggerToast } = useToast();
  const { logo, loginParagraph } = useBranding();

  // Remove trailing route string and redirect to '/'
  // useEffect(() => {
  //   if (navigate || pathname) {
  //     navigate('/');
  //   }
  // }, [navigate, pathname]);

  return (
    <>
      <Helmet>
        <title>{`Something went wrong | RotaPad`}</title>
      </Helmet>
      <div className={`formBody text-center `}>
        <ErrorStyledDiv className='shadow-sm form-signin w-100 m-auto'>
          <form noValidate>
            {logo?.fullLogo ? (
              <>
                <img
                  src={logo?.fullLogo}
                  alt='Logo'
                  className='mb-2'
                />
                <br />
                <img
                  src={Logo}
                  width='100'
                  height='21'
                  className='mb-4'
                  alt='ROTA PAD logo'
                />
              </>
            ) : (
              <img
                src={Logo}
                width='140'
                height='32'
                className='mb-4'
                alt='ROTA PAD logo'
              />
            )}
            <div className='border-top border-bottom py-4 mb-4'>
              <div className='form-floating'>
                <FiInfo style={{ fontSize: '36px', color: 'orange' }} />
              </div>
              <h1 className='h6 mt-2 mb-1 fw-normal'>
                Something went wrong...
              </h1>
              <p style={{ fontSize: '12px' }}>
                Try returning to the dashboard by clicking the button below. For
                assistance, please call <br />
                <span>+1 587 404 2004</span> or send an email to
                support@rotapad.com
              </p>
            </div>

            <ButtonBase
              variant='primary'
              size='large'
              onClick={(event) => {
                event.preventDefault();
                navigate('/dashboard');
              }}
              // disabled={isLoginLoading || !email || !password}
            >
              Dashboard
              {isLoginLoading && (
                <span
                  className='spinner-border spinner-border-sm'
                  role='status'
                  aria-hidden='true'
                ></span>
              )}
            </ButtonBase>

            <p className='mt-5 mb-1 text-muted'>&copy;2023 Rota Pad v3.0</p>
            {/* <p className='text-muted p-0'>{loginParagraph || ''}</p> */}
          </form>
        </ErrorStyledDiv>
      </div>
    </>
  );
};

ErrorPage.defaultProps = {
  theme: { primary: 'red' },
};
export default ErrorPage;
