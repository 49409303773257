import { ToolTip } from '..';
const Pill = ({ toolTipMessage, bgStyle, content, onClick }) => {
  return (
    <ToolTip
      placement='top'
      overlayClassName='tooltipCustom'
      overlay={
        <span
          style={{
            fontSize: 10,
          }}
        >
          {toolTipMessage}
        </span>
      }
    >
      <button
        type='button'
        style={{
          cursor: 'auto',
          ...bgStyle,
          color: '#fff',
          fontSize: '10px',
          height: '20px',
          border: 'none',
          borderRadius: '10px',
          lineHeight: '19px',

          marginRight: '4px',
        }}
        {...(onClick ? { onClick: onClick } : {})}
      >
        {content}
      </button>
    </ToolTip>
  );
};

export default Pill;
