import styled from '@emotion/styled';
import { useCallback } from 'react';
import { MdOpenInNew } from 'react-icons/md';
import { RiEditLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { Card as PageCard, Pill, RoundButtonBase, ToolTip } from '../../common';
import { BADGE_STYLE, USER_TYPES } from '../../common/constants';
import { useApiService } from '../../context';
import { AiOutlineDelete } from 'react-icons/ai';
import { SimpleDialogContainer, simpleConfirm } from 'react-simple-dialogs';

const RoundedPill = styled.div`
  padding: 7px;
  color: rgb(255, 255, 255);
  background-color: rgba(89, 161, 49, 0.66);
  font-size: 12px;
`;

const RoundedCircle = styled.div`
  padding: 4px;
  background: #0000004f;
  line-height: 7px;
  cursor: pointer;
  &:hover {
    background: #00000098;
  }
`;

const TimeCheckStyledDiv = styled.span`
  background: #00000036;
  border-radius: 5px;
  padding: 2px;
  margin-left: 8px;
  font-size: 11px;
  letter-spacing: 0.04rem;
  &:hover {
    background: #000000a1;
  }
`;

const StyledCard = styled.div`
  cursor: pointer;
  // width: 15rem;
  font-size: 12px;
  padding-top: 0px;
  min-height: 211px;
  border: #af97d8 solid 3px;
  img {
    width: 50%;
  }

  div > svg {
    font-size: 34px;
    color: #af97d8;
  }
  &:hover {
    border: #b2d897 solid 3px;

    div > svg {
      color: #b2d897;
    }
  }
`;
const StyledDescriptionDiv = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;
const Card = ({
  email,
  firstname,
  lastname,
  group,
  role,
  uid,
  active,
  endUserConsent,
  payRate,
  createdAt,
  setNewGroup,
  setVisibleLeft,
  type,
  canRotaPadUserUpdate = false,
}) => {
  const {
    deleteDoc,
    setRotaPadInvitations,
    fetchData,
    database,
    setRotaPadAbsences,
    setRotaPadWorkOrders,
    setRotaPadWorkSpaces,
    isRotaPadUserAdmin,
    setRotaPadWorkflows,
  } = useApiService();
  const navigate = useNavigate();

  const handleClick = useCallback(async () => {
    if (
      await simpleConfirm({
        title: `${
          type && type === 'invite'
            ? '🗑️ Delete Invitation'
            : '🗑️ Delete Team Member'
        }`,
        message: `Do you want to delete "${firstname} ${lastname}"?`,
        confirmLabel: 'Delete',
        cancelLabel: 'Cancel',
      })
    ) {
      console.log('Confirmed! ;)');
      const DeleteGroup = await deleteDoc({
        db: database,
        // path: 'users/' + uid,
        path: `${type && type === 'invite' ? 'invites/' : 'users/'}` + uid,
      });
    } else {
      console.log('Not confirmed. 🥲');
    }

    // const fetchGroups = await fetchData({
    // 	db: database,
    // 	path: "workflows",
    // });

    // // console.log("absences ===== ", fetchGroups);
    // if (fetchGroups) {
    // 	setRotaPadWorkflows(fetchGroups);
    // }
  }, [
    database,
    deleteDoc,
    fetchData,
    setRotaPadWorkflows,
    uid,
    simpleConfirm,
    firstname,
    lastname,
    type,
  ]);

  return (
    <>
      <div className='col-xs-12 col-sm-6 col-md-4 col-lg-3'>
        <PageCard
          className='card shadow-sm mb-3'
          // clickable={true}
          // onClick={() => {
          //   setNewGroup({
          //     email,
          //     firstname,
          //     lastname,
          //     group,
          //     role,
          //     uid,
          //     active,
          //     endUserConsent,
          //     payRate,
          //     createdAt,
          //     type,
          //   });
          //   setVisibleLeft(true);
          // }}
        >
          <div className='ps-3 bg-transparent pt-3 pe-3'>
            {isRotaPadUserAdmin && (
              <div
                className='fw-semibold'
                style={{
                  fontSize: '11px',
                  color: 'rgb(141, 141, 142)',
                  // marginBottom: '-4px',
                  // marginTop: '-4px',
                  lineHeight: '12px',
                }}
              >
                {email}
              </div>
            )}

            <div
              className='pb-2 text-uppercase '
              style={{
                fontSize: '12px',
                fontWeight: '600',
                // color: '#a9a9a9',
                // marginBottom: '-4px',
                // marginTop: '-4px',
                lineHeight: '22px',
              }}
            >
              {firstname + ' ' + lastname}
            </div>
            {type && type === 'invite' && (
              <Pill
                content='Invited'
                bgStyle={{ ...BADGE_STYLE.Unit }}
                toolTipMessage='User has been invited'
              />
            )}

            <Pill
              content={role === USER_TYPES.Support ? 'Swiss Support' : role}
              bgStyle={{ ...BADGE_STYLE[role] }}
              toolTipMessage='Role'
            />
            <Pill
              content={active ? 'Active' : 'Inactive'}
              bgStyle={
                active ? { ...BADGE_STYLE.Active } : { ...BADGE_STYLE.Inactive }
              }
              toolTipMessage='Account status'
            />
          </div>

          <div className='card-body '>
            <Pill
              content={!group ? `No group assigned` : `${group}`}
              bgStyle={{ ...BADGE_STYLE.Time }}
              toolTipMessage='User group'
            />
          </div>

          {canRotaPadUserUpdate && (
            <div className='card-footer bg-transparent '>
              <ToolTip
                placement='top'
                overlay={<span style={{ fontSize: 10 }}>Update</span>}
              >
                <RoundButtonBase
                  variant='outline'
                  onClick={() => {
                    setNewGroup({
                      email,
                      firstname,
                      lastname,
                      group,
                      role,
                      uid,
                      active,
                      endUserConsent,
                      payRate,
                      createdAt,
                      type,
                    });
                    setVisibleLeft(true);
                  }}
                  type='button'
                  size='small'
                  className={` me-2 `}
                >
                  <RiEditLine />
                </RoundButtonBase>
              </ToolTip>

              <ToolTip
                placement='top'
                overlay={<span style={{ fontSize: 10 }}>Delete</span>}
              >
                <RoundButtonBase
                  type='button'
                  size='small'
                  onClick={handleClick}
                  className={` me-2 `}
                  variant='outline'
                >
                  <AiOutlineDelete />
                </RoundButtonBase>
              </ToolTip>
              <SimpleDialogContainer
                primaryColor='#dc3545'
                primaryHoverColor='#a42531'
              />
            </div>
          )}
        </PageCard>
      </div>
    </>
  );
};

export default Card;
