import styled from '@emotion/styled';
import { ReportContext } from '../../../reports/ReportContext';
import { useContext } from 'react';
import { Pill } from '../../../common';
import { BADGE_STYLE } from '../../../common/constants';
import { BsCaretRightFill } from 'react-icons/bs';
import Entry from './Entry';
import _ from 'lodash';

const TotalDueSpanStyled = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #8f8f8f;
`;

const TotalValueStyledSpan = styled.span`
  font-size: 14px;
  font-weight: 600;
`;
const AtAGlanceStyledDIv = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #8f8f8f;
`;
const DateRangeStyledDIv = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => props.theme.primary};
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme[`primary-400`]};
  }
`;
const BorderedBottomDiv = styled.div`
  border-bottom: 1px solid #e3e3e3;
  padding: 0 8px 4px 8px;
  margin-bottom: 16px;
`;

const BorderedTopDiv = styled.div`
  border-top: 1px solid #e3e3e3;
  padding: 0 8px 4px 8px;
  margin-top: 16px;
`;
const EntryContainerStyledDiv = styled.div`
  border: 1px solid #e3e3e3;
`;

const Details = ({ computedShifts, teamMembers, unitPrintName, customers }) => {
  //setExpandAllUnits
  const { setExpandAllUnits, expandAllUnits, hideHeaders } =
    useContext(ReportContext);

  return (
    <div>
      <div className='d-flex flex-column mt-0 mb-0 pt-3'>
        <BorderedBottomDiv className='d-flex justify-content-between'>
          <AtAGlanceStyledDIv>Account details</AtAGlanceStyledDIv>
          <DateRangeStyledDIv
            onClick={() => setExpandAllUnits(!expandAllUnits)}
          >
            {expandAllUnits ? 'Condense All' : 'Expand All'}
          </DateRangeStyledDIv>
        </BorderedBottomDiv>

        <EntryContainerStyledDiv
          style={{
            height: !hideHeaders
              ? 'calc(100vh - 363px)'
              : 'calc(100vh - 700px)',
            overflow: 'auto',
          }}
        >
          {teamMembers &&
            teamMembers.length > 0 &&
            teamMembers.map(({ firstname, lastname, uid }) => {
              return (
                <Entry
                  key={uid}
                  uid={uid}
                  name={`${firstname} ${lastname}`}
                  computedShifts={
                    computedShifts
                      ? computedShifts.filter((computedShift) =>
                          _.find(computedShift.attendees, { uid })
                        )
                      : []
                  }
                  unitPrintName={unitPrintName}
                  customers={customers}
                />
              );
            })}
        </EntryContainerStyledDiv>

        {/* <BorderedTopDiv className='d-flex justify-content-end text-end'>
          <div className='mt-1'>
            <TotalDueSpanStyled>Total Due:</TotalDueSpanStyled>{' '}
            <TotalValueStyledSpan>$51.71</TotalValueStyledSpan>
          </div>
        </BorderedTopDiv> */}
      </div>
    </div>
  );
};
export default Details;
