import styled from '@emotion/styled';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import TeamMemberAddress from '../TeamMemberAddress';
import { Checkbox } from 'primereact/checkbox';
import { StyledInputPayRate } from '../../../customers/AddCustomerForm';
import { InputNumber } from 'primereact/inputnumber';
import { useApiService } from '../../../context';
import _ from 'lodash';

export const StyledDropDownDiv = styled.div`
  // border-width: 0 0 0 2px;
  // border-style: solid;
  // padding-left: 12px;
  // border-color: #6554c0;
  color: #6554c0;
  font-size: 18px;
  .customDropdown {
    width: 100%;
  }
  .customDropdown:not(.p-disabled):hover {
    border-color: #6366f1;
  }

  .customDropdown .p-dropdown-label {
    background: transparent;
    border: 0 none;
    padding: 8px 8px;
    font-size: 12px;
  }

  .customDropdown .p-dropdown-trigger {
    width: 2rem;
  }

  .customDropdown .p-dropdown-trigger-icon {
    font-size: 10px;
  }

  .p-disabled,
  .p-component:disabled {
    opacity: 0.6;
    background-color: #e9ecef;
  }
`;

export const StyledInput = styled.input`
  padding: 8px 8px;
  font-size: 12px;
  width: 100% !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: #495057;
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  appearance: none;
  border-radius: 6px;
  margin: 0;

  &:hover {
    border-color: #6366f1;
  }
  &:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #c7d2fe;
    border-color: #6366f1;
  }
  &:disabled {
    background-color: #e9ecef;
    opacity: 0.6;
    &:hover {
      border-color: #ced4da;
    }
  }
`;

const StyledLabel = styled.label`
  font-size: 12px;
  display: inline-block;
  font-weight: 500;
`;
const StyledH5 = styled.h5`
  // border-width: 0 0 0 2px;
  // border-style: solid;
  // padding-left: 12px;
  // border-color: #6554c0;
  color: #6554c0;
  font-size: 18px;
  padding-top: 16px;
`;
const StyledDiv = styled.div`
  right: 12px !important;
  top: 40px !important;
  cursor: pointer;
  color: #6c757d;

  &:hover {
    color: #7f00ff;
  }

  i {
    cursor: pointer;
    z-index: 200;
    font-size: large;
    color: #6c757d;

    &:hover {
      color: #7f00ff;
    }
  }
`;

const TeamMemberForm = ({
  handleSubmit,
  uid,
  pristine,
  submitting,
  hasValidationErrors,
  visibleLeft,
  rotaPadUser,
  disabled,
  isSelf = false,
}) => {
  const [showNewPassword, setShowNewPassword] = useState({
    hide: true,
    info: 'Show',
  });

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return (
      isFormFieldValid(meta) && <small className='p-error'>{meta.error}</small>
    );
  };
  const { rotaPadUserGroupsTopic, isRotaPadUserAdmin } = useApiService();

  // const isSelf = false;
  const isUserAdmin = isRotaPadUserAdmin;
  const convertUserGroupstoArray = useMemo(() => {
    return _.isObject(rotaPadUserGroupsTopic) &&
      Object.keys(rotaPadUserGroupsTopic).length > 0
      ? Object.keys(rotaPadUserGroupsTopic).map((key) => {
          return {
            value: rotaPadUserGroupsTopic[key].name,
            ...rotaPadUserGroupsTopic[key],
          };
        })
      : [];
  }, [rotaPadUserGroupsTopic]);
  return (
    <div className='w-100 p-3'>
      <div className='row g-3'>
        <div className='col-12 mb-1'>
          <Field
            name='firstname'
            render={({ input, meta }) => (
              <div className='field'>
                <div className='block'>
                  <StyledLabel
                    htmlFor='firstname'
                    className={classNames({
                      'p-error': isFormFieldValid(meta),
                    })}
                  >
                    First Name <span className='text-danger'>*</span>
                  </StyledLabel>
                  <StyledInput
                    id='firstname'
                    {...input}
                    className={classNames({
                      'p-invalid': isFormFieldValid(meta),
                    })}
                    disabled={!isUserAdmin || isSelf || disabled}
                  />
                </div>
                {getFormErrorMessage(meta)}
              </div>
            )}
          />
        </div>

        <div className='col-12 mt-1 mb-1'>
          <Field
            name='lastname'
            render={({ input, meta }) => (
              <div className='field'>
                <div className='block'>
                  <StyledLabel
                    htmlFor='lastname'
                    className={classNames({
                      'p-error': isFormFieldValid(meta),
                    })}
                  >
                    Last Name <span className='text-danger'>*</span>
                  </StyledLabel>
                  <StyledInput
                    id='lastname'
                    {...input}
                    className={classNames({
                      'p-invalid': isFormFieldValid(meta),
                    })}
                    disabled={!isUserAdmin || isSelf || disabled}
                  />
                </div>
                {getFormErrorMessage(meta)}
              </div>
            )}
          />
        </div>
        {isUserAdmin && (
          <div className='col-12 mt-1 mb-1'>
            <Field
              name='email'
              render={({ input, meta }) => (
                <div className='field'>
                  <div className='block'>
                    <StyledLabel
                      htmlFor='email'
                      className={classNames({
                        'p-error': isFormFieldValid(meta),
                      })}
                    >
                      Email <span className='text-danger'>*</span>
                    </StyledLabel>
                    <StyledInput
                      id='email'
                      {...input}
                      className={classNames({
                        'p-invalid': isFormFieldValid(meta),
                      })}
                      disabled={
                        uid !== undefined || !isUserAdmin || isSelf || disabled
                      }
                      // disabled={!isUserAdmin}
                    />
                  </div>
                  {getFormErrorMessage(meta)}
                </div>
              )}
            />
          </div>
        )}

        {isUserAdmin && (
          <div className='col-12 mt-1 mb-1'>
            <Field
              name='payRate'
              render={({ input, meta }) => {
                // console.log('input ===== ', input);
                return (
                  <div className='field'>
                    <div className='block'>
                      <StyledLabel htmlFor='phone'>Pay rate</StyledLabel>
                      <StyledInputPayRate>
                        <InputNumber
                          inputId='stacked'
                          // {...input}
                          value={input.value || 0}
                          onValueChange={(e) => {
                            // console.log('asdasdas ====', e.value);
                            input.onChange(e.value);
                          }}
                          showButtons
                          mode='currency'
                          currency='GBP'
                          className='w-100'
                          disabled={!isUserAdmin || disabled || isSelf}
                        />
                      </StyledInputPayRate>
                    </div>
                  </div>
                );
              }}
            />
          </div>
        )}
      </div>

      {/* <Field
				name='address'
				label='address'
				component={TeamMemberAddress}
				labelPosition='right'
				visibleLeft={visibleLeft}
				disabled={isSelf}
			/> */}

      <StyledH5 className='mt-3 mb-2'>Assignment</StyledH5>
      <div className='row g-3'>
        <div className='col-12 mb-1'>
          <Field
            name='role'
            render={({ input, meta }) => (
              <div className='field'>
                <div className='block'>
                  <StyledLabel
                    htmlFor='role'
                    className={classNames({
                      'p-error': isFormFieldValid(meta),
                    })}
                  >
                    Role <span className='text-danger'>*</span>
                  </StyledLabel>
                  <StyledDropDownDiv>
                    <Dropdown
                      id='role'
                      {...input}
                      options={[
                        { name: 'Admin', value: 'Admin' },
                        { name: 'Swiss Support', value: 'Support' },
                        { name: 'User', value: 'User' },
                      ]}
                      optionLabel='name'
                      className='customDropdown'
                      disabled={!isUserAdmin || disabled || isSelf}
                    />
                  </StyledDropDownDiv>
                </div>
                {getFormErrorMessage(meta)}
              </div>
            )}
          />
        </div>

        <div className='col-12 mt-1 mb-1'>
          <Field
            name='group'
            render={({ input, meta }) => (
              <div className='field'>
                <div className='block'>
                  <StyledLabel
                    htmlFor='group'
                    className={classNames({
                      'p-error': isFormFieldValid(meta),
                    })}
                  >
                    Group <span className='text-danger'>*</span>
                  </StyledLabel>
                  <StyledDropDownDiv>
                    <Dropdown
                      id='group'
                      {...input}
                      options={convertUserGroupstoArray}
                      optionLabel='name'
                      className='w-100 p-inputtext-sm customDropdown'
                      disabled={!isUserAdmin || disabled}
                    />
                  </StyledDropDownDiv>
                </div>
                {getFormErrorMessage(meta)}
              </div>
            )}
          />
        </div>
      </div>
      <StyledH5 className='mt-3 mb-2'>Privacy and security</StyledH5>
      <div className='row g-3'>
        <div className='col-12 mb-1'>
          <Field
            name='active'
            type='checkbox'
            render={({ input, meta }) => (
              <div className='field'>
                <div className='block'>
                  <Checkbox
                    inputId='active'
                    {...input}
                    disabled={!isUserAdmin || disabled || isSelf}
                  />
                  <StyledLabel
                    htmlFor='active'
                    className='ms-2'
                  >
                    Active
                  </StyledLabel>
                </div>
              </div>
            )}
          />
        </div>

        <div className='col-12 mt-1 mb-1'>
          <Field
            name='endUserConsent'
            type='checkbox'
            render={({ input, meta }) => (
              <div className='field'>
                <div className='block'>
                  <Checkbox
                    inputId='endUserConsent'
                    {...input}
                    disabled={!isUserAdmin || disabled || isSelf}
                  />
                  <StyledLabel
                    htmlFor='endUserConsent'
                    className='ms-2'
                  >
                    End User Consent
                  </StyledLabel>
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default TeamMemberForm;
