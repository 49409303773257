import _ from 'lodash';

export const convertObjectToArray = ({ item }) => {
  return _.isObject(item) && Object.keys(item).length > 0
    ? Object.keys(item).map((key) => {
        return { ...item[key] };
      })
    : [];
};

export const formatCurrency = (value) => {
  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'GBP',
  });
};

export const formatHour = (value) => {
  return value.toFixed(2);
};

export const getUnitName = ({ unitId, customers }) => {
  if (_.isEmpty(customers)) {
    return null;
  }

  // Get unit details using unitId - unitName
  // Step 1: Get all units
  const unitsCollection = _.reduce(
    customers || [],
    function (sum, n) {
      const { units = [], id, name } = n;
      const newUnitsArray = units.map((obj) => {
        return {
          ...obj,
          customerId: id,
          customerName: name,
        };
      });
      return [...sum, ...newUnitsArray];
    },
    []
  );

  // Get unit details
  const unitDetails = _.find(unitsCollection, { id: unitId });

  return {
    // year: '2023',

    unitPrintName: unitDetails && unitDetails.name,
    unitPrintId: unitDetails && unitDetails.unitId,
    unitPrintAddress: unitDetails && unitDetails.address,
    customerId: unitDetails && unitDetails.customerId,
    customerName: unitDetails && unitDetails.customerName,
    customerStaff: unitDetails && unitDetails.customerStaff,
  };
};

export const getShiftNotificationBody = ({
  email,
  firstname,
  lastname,
  shiftName,
  subject,
  verb,
}) => {
  return {
    sender: { name: 'RotaPad', email: 'noreply@rotapad.com' },
    to: [
      {
        email,
        name: firstname + ' ' + lastname,
      },
    ],
    subject,
    htmlContent: `<!doctype html>
      <html>
        <head>
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
          <title>Simple Transactional Email</title>
          <style>
      @media only screen and (max-width: 620px) {
        table.body h1 {
          font-size: 28px !important;
          margin-bottom: 10px !important;
        }
      
        table.body p,
      table.body ul,
      table.body ol,
      table.body td,
      table.body span,
      table.body a {
          font-size: 16px !important;
        }
      
        table.body .wrapper,
      table.body .article {
          padding: 10px !important;
        }
      
        table.body .content {
          padding: 0 !important;
        }
      
        table.body .container {
          padding: 0 !important;
          width: 100% !important;
        }
      
        table.body .main {
          border-left-width: 0 !important;
          border-radius: 0 !important;
          border-right-width: 0 !important;
        }
      
        table.body .btn table {
          width: 100% !important;
        }
      
        table.body .btn a {
          width: 100% !important;
        }
      
        table.body .img-responsive {
          height: auto !important;
          max-width: 100% !important;
          width: auto !important;
        }
      }
      @media all {
        .ExternalClass {
          width: 100%;
        }
      
        .ExternalClass,
      .ExternalClass p,
      .ExternalClass span,
      .ExternalClass font,
      .ExternalClass td,
      .ExternalClass div {
          line-height: 100%;
        }
      
        .apple-link a {
          color: inherit !important;
          font-family: inherit !important;
          font-size: inherit !important;
          font-weight: inherit !important;
          line-height: inherit !important;
          text-decoration: none !important;
        }
      
        #MessageViewBody a {
          color: inherit;
          text-decoration: none;
          font-size: inherit;
          font-family: inherit;
          font-weight: inherit;
          line-height: inherit;
        }
      
        .btn-primary table td:hover {
          background-color: #34495e !important;
        }
      
        .btn-primary a:hover {
          background-color: #34495e !important;
          border-color: #34495e !important;
        }
      }
      </style>
        </head>
        <body style="background-color: #f6f6f6; font-family: sans-serif; -webkit-font-smoothing: antialiased; font-size: 14px; line-height: 1.4; margin: 0; padding: 0; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
          <span class="preheader" style="color: transparent; display: none; height: 0; max-height: 0; max-width: 0; opacity: 0; overflow: hidden; mso-hide: all; visibility: hidden; width: 0;">This is preheader text. Some clients will show this text as a preview.</span>
          <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #f6f6f6; width: 100%;" width="100%" bgcolor="#f6f6f6">
            <tr>
              <td style="font-family: sans-serif; font-size: 14px; vertical-align: top;" valign="top">&nbsp;</td>
              <td class="container" style="font-family: sans-serif; font-size: 14px; vertical-align: top; display: block; max-width: 580px; padding: 10px; width: 580px; margin: 0 auto;" width="580" valign="top">
                <div class="content" style="box-sizing: border-box; display: block; margin: 0 auto; max-width: 580px; padding: 10px;">
      
                  <!-- START CENTERED WHITE CONTAINER -->
                  <table role="presentation" class="main" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; background: #ffffff; border-radius: 3px; width: 100%;" width="100%">
      
                    <!-- START MAIN CONTENT AREA -->
                    <tr>
                      <td class="wrapper" style="font-family: sans-serif; font-size: 14px; vertical-align: top; box-sizing: border-box; padding: 20px;" valign="top">
                        <table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;" width="100%">
                          <tr>
                            <td style="font-family: sans-serif; font-size: 14px; vertical-align: top;" valign="top">
                            <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 15px;"><img src="https://rotapad.com/clients/images/xynexcare_logo.png"  alt="user-img" style="width: 65px; height: 65px; cursor: pointer;"></p>
                              <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 15px;">Hi ${
                                firstname + ' ' + lastname
                              },</p>
                              <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 15px;">You have been ${verb} a shift <strong>${shiftName}</strong>. <br /><br />Click on the button below view available shifts on your XynexCare RotaPad account.</p>
                              <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="btn btn-primary" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; box-sizing: border-box; width: 100%;" width="100%">
                                <tbody>
                                  <tr>
                                    <td align="left" style="font-family: sans-serif; font-size: 14px; vertical-align: top; padding-bottom: 15px;" valign="top">
                                      <table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: auto;">
                                        <tbody>
                                          <tr>
                                            <td style="font-family: sans-serif; font-size: 14px; vertical-align: top; border-radius: 5px; text-align: center; background-color: #3498db;" valign="top" align="center" bgcolor="#3498db"> <a href="https://xynex.rotapad.com/dashboard" target="_blank" style="border: solid 1px #3498db; border-radius: 5px; box-sizing: border-box; cursor: pointer; display: inline-block; font-size: 14px; font-weight: bold; margin: 0; padding: 12px 25px; text-decoration: none; text-transform: capitalize; background-color: #3498db; border-color: #3498db; color: #ffffff;">Go to dashboard</a> </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin-bottom: 5px;">Remember to record the time spent on this shift. </p>
                              <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 15px;">Have a nice day ahead!</p>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
      
                  <!-- END MAIN CONTENT AREA -->
                  </table>
                  <!-- END CENTERED WHITE CONTAINER -->
      
                  <!-- START FOOTER -->
                  <div class="footer" style="clear: both; margin-top: 10px; text-align: center; width: 100%;">
                    <table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;" width="100%">
                      <tr>
                        <td class="content-block" style="font-family: sans-serif; vertical-align: top; padding-bottom: 10px; padding-top: 10px; color: #999999; font-size: 12px; text-align: center;" valign="top" align="center">
                          <span class="apple-link" style="color: #999999; font-size: 12px; text-align: center;">Rota Pad Services Inc. T2Z 1J9, Calgary AB.</span>
                          <br> Do you want fnd out more? <a href="https://rotapad.com" style="text-decoration: underline; color: #999999; font-size: 12px; text-align: center;">RotaPad.com</a>.
                        </td>
                      </tr>
                      <tr>
                        <td class="content-block powered-by" style="font-family: sans-serif; vertical-align: top; padding-bottom: 10px; padding-top: 10px; color: #999999; font-size: 12px; text-align: center;" valign="top" align="center">
                          Powered by <a href="https://rotapad.com" style="color: #999999; font-size: 12px; text-align: center; text-decoration: none;">RotaPad APIs</a>.
                        </td>
                      </tr>
                    </table>
                  </div>
                  <!-- END FOOTER -->
      
                </div>
              </td>
              <td style="font-family: sans-serif; font-size: 14px; vertical-align: top;" valign="top">&nbsp;</td>
            </tr>
          </table>
        </body>
      </html>`,
    headers: {
      charset: 'iso-8859-1',
    },
  };
};
